.slideContainer {
    display: flex;
    flex-direction: column;
    gap:5vw
}

.sliders {
    width: 200px;
}
.first {
    display: flex;
   
  

}

.inners {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 50%;
    padding: 0px 3vw;
}

.staticAboutContainer {
    padding: 0px 3vw;
    display: flex;
    flex-direction: column;
    gap: 2vw;
}
.about2Container {
   
    padding: 10px;
    text-align: center;
}

   @media (max-width: 980px) {
    
   

.imgContainer {
    width:100%;
}
.inners {
    width:100%;
    gap: 2vw;
       
    
    
}}



.imgContainer {
    width: 50%;
    padding: 5px;
    border: 1px solid;
    border-radius: 5px;
}
.img21 {
    width: 100%;
    height: 100%;
    border-radius: 5px;
   
}
.second {
    display: flex;
    flex-direction: row-reverse;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    text-align: center;
}
.third {
    display: flex;
    flex-direction: row-reverse;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    text-align: center;
}
@media  (max-width: 960px) {
    .imgContainer {
        width: 100%;
        padding: 5px;
        border: 1px solid;
    }
    .second {
        flex-direction: column-reverse;
    }
}