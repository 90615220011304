.container1 {
  width: 470px;
}
@media (max-width: 480px) {
  .container1{
    width: 280px;
  }}
.wrapper {
    position: relative;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    display: flex;
    align-items: center;
    overflow: hidden;
    background-color: black;
    /* i {
      font-size: 2vw;
    } */
  }
  
  .slide {
    min-width: 100%;
    height: 100%;
    transition: 0.5s;
    overflow: hidden;
  }
  
  #leftBtn {
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    width: 10%;
    height: 80%;
    background: none;
    border: none;
    outline: none;
    transition: 0.5s;
    color: var(--primary-color)
    /* &:hover {
      background: rgba(0, 0, 0, 0.421);
      cursor: pointer;
      i {
        color: white;
      }
    } */
  }
  
  #rightBtn {
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    width: 10%;
    height: 80%;
    background: none;
    border: none;
    outline: none;
    transition: 0.5s;
    color: var(--primary-color)
    /* &:hover {
      background: rgba(0, 0, 0, 0.421);
      cursor: pointer;
      i {
        color: white;
      }
    } */
  }
  