
.masnoryContainer {
    margin-bottom: calc(3rem + 5.128vw);

}

/* .grid-container {
    display: grid !important;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 1.5vw;
  }

  .po_item {
    position: relative;
    width: 100%;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #fff;
    margin-bottom: 1.5vw;
    border-radius: 10px;
  }
  .po_item {

  .po_item.col-span-1 {
    grid-column: span 1;
  }
  
  .po_item.col-span-2 {
    grid-column: span 2;
  }
  
  .po_item:nth-child(odd) {
    grid-column: span 1 / span 2;
  }
  
  .po_item:nth-child(even) {
    grid-column: span 2 / span 3;
  }
  
  
.po_item img {
    width: 100%;
    height: auto;
    object-fit: cover;
  }
  
  .po_item .content {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    opacity: 0;
    transition: opacity 0.3s ease;
  }
  
  .po_item:hover .content {
    opacity: 1;
  }
  
  .po_item .content p {
    margin: 0;
    padding: 10px;
    font-size: 18px;
    text-align: center;
  }
  
  .po_item .content svg {
    cursor: pointer;
  } */
  .po_item {
    border-radius: 5px;
  }
  .po_item image{
    border-radius: 5px;
  }