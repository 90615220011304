.gridContainerr {
    display: grid;
  grid-template-columns: auto auto auto ;
  gap: 10px;
  padding: 10px;
}
.gridItem {
    height: 269px;
    border-radius: 5px;
}
.gridItem img {
  border-radius: 5px;
}
.grid-item-0  {
    grid-column: 1 / 3;
  
  }
  .grid-item-3  {
    grid-column: 2 / 4;
  }
  .grid-item-4  {
    grid-column: 1 / 3;
  }
  .grid-item-7  {
    grid-column: 2 / 4;
  }
  .grid-item-8  {
    grid-column: 1 / 3;
  }
  
  @media (max-width: 425px) {
    .gridItem {
        grid-column : 1/4;
    }
  }
  