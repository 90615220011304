.question {
    padding: 1rem 1.5rem;
    border: 0.5px solid;
    margin-bottom: 1rem;


}
.singleQuestion {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.btn {
    color: var(--secondary-color);
}