.mainfooter{
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 7vw;
    padding-bottom: 10px;
}
@media (max-width: 480px) {
    .mainfooter{
     flex-direction: column-reverse;
    }}
.blog {
    display: flex;
    flex-direction: column;
    text-align: center;
}
.blog ul {
    display: flex;
justify-content: space-evenly;

}
.mid{
    display: flex;
    flex-direction: column;
    text-align: center;
    padding: 0;
}
.linkss{
    

}
.SocialIcons {
    font-size: 2em;
    display: flex;
    padding: 0px;
   

}
.lastbtn {
    margin: 0;
}
