.processContainer {
    display: flex;
    flex-direction: column;
   
}
.inner {
    display: flex;  
    justify-content: space-evenly;
    gap:5px
}

.card1 {
    display: flex;
    align-items: center;
    flex-direction: column;
    text-align: center;
    max-width: 340px;
    border: 1px solid;
    padding: 15px;
    gap: 10px;
    border-radius: 5px;
}
.card1 svg {
    font-size: 2em;
}
@media only screen and (max-width: 790px) {
   .inner {
    flex-wrap: wrap;
   }
}