.bg-video {
  position: absolute;
  width: inherit;
  height: inherit;
  object-fit: cover;
  z-index: -1;
  top: -10px;
}

.volume-icon {
  display: flex;
  gap: 2px;
  position: absolute;
  bottom: 0;
  right: 0;
  padding: 10px;
}
.controls {
  display: flex;
    grid-gap: 2px;
    gap: 10px;
    position: absolute;
    bottom: 5px;
    right: 5px;
    padding: 10px;
}
.arrow {
  position: absolute;
  bottom: 5px;
  right: 50%;
  font-size: 2.5em;
}